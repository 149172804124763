import '../../css/home.css';

require('../common/jquery.hoverVideoPlayer.js');
import {init} from '../common/init.js';
import {commonSearch} from '../common/commonSearch.js';
import {fieldDisplay} from '../common/fieldDisplay.js';


window.initPage = function () {

	$('.binContents .clip').each(function () {
		let el = $(this),
			info = el.data('info');

		if (info.hasVideo) {
			el.find('.resultLink').hoverVideoPlayer({
				videoPath: info.path,
				poster: info.thumbnail,
				offset: info.reelData.in,
				end: info.reelData.out
			});
		}

		el.data('reelData', info.reelData);
		if (el.hasClass('showClip')) {
			el.find('.resultLink').on('click', function (e) {
				e.preventDefault();
				return commonSearch.showResult(el, '.result.showClip');
			});
		}
	});

	$('.banner .close').click(function () {
		$('.banner').addClass('hidden');
		$.cookie('hideBanner', 'true');
	});
};


init.init();
$(document).ready(function() {
	init.initPage();
});